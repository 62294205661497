.App {
  text-align: center;
}

.password-input {
  padding-right: 2.5rem;

}

.password-toggle-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  color: #6c757d;
}

.login-form {
  margin-top: 20px;
}

