@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply cursor-pointer;
  }
  body {
    font-family: "Noto Sans", sans-serif;
    background: #F3F3F8;
  }
  .select-input-arrow {
    background-position: right 1.48rem center !important;
  }
}

@layer components {
  .sidebar {
    @apply transition-all overflow-hidden;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    transition-delay: 10ms;
  }
  .main-content {
    @apply transition-all;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    transition-delay: 10ms;
  }
  .sidebar > div {
    @apply bg-transparent;
  }
  .sidebar-items ul li a {
    @apply text-sm xl:text-base font-medium text-secondary-700 p-[10px] xl:p-[18px] min-w-full inline-flex;
  }
  .sidebar-items ul li a svg {
    @apply text-secondary-800;
  }
  .sidebar-item .item-title {
    @apply pl-3
  }
  .sidebar-item span {
    @apply p-0
  }
  .active-item {
    @apply bg-badge-gray rounded-[100px] !important;
  }
  .active-item svg path {
    @apply text-secondary-800;
  }
  .active-item span {
    @apply text-secondary-800;
  }
  .card {
    @apply bg-white p-4 rounded-3xl;
  }
  .sidebar-toggle {
    @apply w-[80px] !important;
  }
  .sidebar-toggle ul li a{
  @apply justify-start;
  }
  .sidebar-item img {
    @apply max-w-5;
  }
  .sidebar-toggle .sidebar-item .item-title{
    @apply hidden;
  }
  /* .sidebar-toggle .sidebar-logo{
    @apply mx-1;
  } */
  .listing-border {
    @apply border-b border-b-border;
  }
  .listing-border:last-child {
    @apply border-b-0;
  }
  .badge {
    @apply rounded-2xl px-2 py-1 text-sm text-secondary-100 font-semibold leading-[18px];
  }
  .card-details li {
    @apply flex items-center justify-between border-b border-b-border pb-3 mb-3
  }
  .add-attorney {
    @apply border-b border-b-border pb-3 mb-3
  }
  .card-details li:last-child {
    @apply border-b-0 pb-0 mb-0
  }
  .card-details li span.left-txt {
    @apply lg:text-sm xl:text-base text-secondary-800 font-medium
  }
  .card-details .profile {
    @apply justify-start
  }
  .card-details .profile.content-between {
    @apply justify-between
  }
  .card-details .profile .left-txt {
    @apply text-xl
  }
  .progress-bars>div {
    @apply flex-1
  }
  .custom-progress {
    @apply h-10 rounded-lg bg-badge-gray !important
  }
  .custom-progress>div {
    @apply h-10 rounded-lg bg-primary2 text-white text-xs leading-10 !important
  }
  .new-case-modal [type='checkbox']:checked {
    @apply bg-primary2;
  }
  .custom-radio [type='radio']:checked {
    @apply text-primary2 focus:ring-transparent
  }
  .btn-theme {
    @apply bg-primary text-sm text-white py-[10px] px-6 rounded-[100px]
  }
  .btn-disabled {
    @apply bg-bg-input text-secondary-300
  }
  .btn-theme.btn-gray {
    @apply bg-badge-gray text-secondary-800
  }
  .btm-border-0 {
    @apply border-b-0 !important
  }
  .form-input::placeholder {
    @apply text-secondary-700
  }

  .list-w-full {
    @apply block !important
  }
  .list-w-full li {
    @apply border-b-0 mb-2 pb-2 px-0 !important
  }
  .list-w-full li:last-child {
    @apply mb-0 pb-0 !important
  }
  .right-listing li {
    @apply pb-5 mb-5
  }
  .new-case-modal > div{
    @apply max-w-[656px]
  }
  .calendar-modal > div{
    @apply max-w-[480px]
  }
  .new-case-modal > div > div{
    @apply rounded-3xl
  }
  .new-case-modal input::placeholder {
    @apply text-secondary-600
  }
  .new-case-modal input:focus {
    @apply ring-transparent
  }
  .task-checklist {
    @apply border-b border-b-border pl-4 pr-2
  }
  .task-checklist:last-child {
    @apply border-b-0
  }
  .task-checklist input[type="checkbox"] {
    @apply bg-white border-2 border-secondary-800 w-[18px] h-[18px] rounded-sm
  }
  .task-checklist input[type="checkbox"]:checked {
    @apply bg-primary2 border-primary2
  }
  .task-checklist input[type="checkbox"]:focus {
    @apply ring-transparent
  }
  .contact-accordian-title svg {
    @apply opacity-40
  }
  .contact-accordian-body li {
    @apply px-0
  }
  .spinner-overlay {
    @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm flex justify-center items-center z-[9999]
  }
  .spinner-container {
    @apply relative
  }
  .spinner-container svg {
    @apply w-[50px] h-[50px] fill-primary
  }
  .stepper-li:last-child {
    @apply w-auto after:hidden
  }
  .custom-datepicker input {
    @apply bg-input-surface py-[10px] pl-9 pr-4 rounded-full border-0 text-sm leading-5 font-semibold text-label
  }
  .contacts-table {
    @apply rounded-2xl shadow-shadow-light px-4 bg-white
  }
  .contacts-table.db-contacts{
    @apply bg-input-surface 
  }
  .contacts-table.db-contacts td{
    @apply bg-input-surface 
  }
  .contacts-table.db-contacts th{
    @apply bg-input-surface 
  }
  .contacts-table th {
    @apply py-[14px] bg-white text-sm text-secondary-800 capitalize font-medium px-4 first:pl-0 last:pr-0
  }
  .contacts-table td {
    @apply py-2 text-sm text-secondary-800 font-medium px-4 first:pl-0 last:pr-0
  }
  /* .db-contacts td {
    @apply bg-input-surface py-[10px]
  }
  .db-contacts input  {
    @apply bg-transparent
  } */
  .contacts-table.addFromContactModal {
    @apply  bg-input-surface
  }
  .contacts-table.addFromContactModal th {
    @apply  bg-input-surface
  }
  .contacts-table.addFromContactModal td {
    @apply  bg-input-surface
  }
  
  .items-dropdown.option-dropdown > button {
    @apply bg-bg-gray-400 py-[6px] px-[14px]
  }
  .items-dropdown.option-dropdown  > button:disabled {
    @apply opacity-40;
  }
  .items-dropdown > button {
    @apply bg-white shadow-shadow-light py-[10px] px-5 rounded-full flex items-center text-base text-secondary-800 font-medium
  }
  .items-dropdown > button:disabled {
    @apply bg-gray-300 shadow-shadow-light py-[10px] px-5 rounded-full flex items-center text-base text-secondary-800 font-medium;
    color: #000000;
    background: var(--State-Layers-On-Surface-Opacity-08, rgba(26, 28, 31, 0.08));
  }
  .items-dropdown ul li button {
    @apply hover:bg-bg-gray-200
  }
  .items-dropdown ul li input[type='checkbox'] {
    @apply bg-transparent border-secondary-800 rounded-sm focus:ring-transparent
  }
  .items-dropdown ul li input[type='checkbox']:checked {
    @apply bg-primary2 border-primary2
  }
  .items-dropdown.single-select ul {
    @apply p-0
  }
  .items-dropdown.single-select ul li:first-child button {
    @apply rounded-tl-[14px] rounded-tr-[14px]
  }
  .items-dropdown.single-select ul li:last-child button {
    @apply rounded-bl-[14px] rounded-br-[14px]
  }
  .new-case-modal .items-dropdown > button {
    @apply bg-input-surface shadow-none py-3 px-4 rounded-[40px] text-sm text-secondary-800 font-normal relative w-full
  }
  .new-case-modal .items-dropdown.default > button {
    @apply  text-secondary-600
  }
  .new-case-modal .items-dropdown > button svg {
    @apply absolute right-3
  }
  /* .new-case-modal select {
    @apply text-text-gray-100
  } */
  /* .new-case-modal .form-input::placeholder {
    @apply text-text-gray-100
  } */
  .sort-by-filter.items-dropdown > button {
    @apply relative w-48
  }
  .sort-by-filter.items-dropdown > button svg {
    @apply absolute right-3
  }
  .calendar-info {
    @apply text-danger-200
  }
  .fc-event:has(.calendar-info) {
    @apply bg-badge-red border-0 border-l-8 border-l-danger-100 p-2
  }
  .calendar-info.yellow {
    @apply text-warning-200
  }
  .fc-event:has(.calendar-info.yellow) {
    @apply bg-badge-yellow border-l-8 border-l-warning-100
  }
  .calendar-info.gray {
    @apply text-secondary-800
  }
  .fc-event:has(.calendar-info.gray) {
    @apply bg-bg-gray-200 border-l-8 border-l-text-gray-200
  }
  .gray-btn button {
    @apply bg-bg-gray-400 shadow-none
  }
  .avatar-hover .edit-btn {
    @apply invisible
  }
  .avatar-hover:hover .edit-btn {
    @apply visible
  }
  .export-pdf-modal > div {
    @apply max-w-[1200px]
  }
  .pagination-btm ul {
    @apply shadow-shadow-light
  }
  .pagination-btm ul button {
    @apply border-0 p-[14px] bg-white
  }
  .custom-datepicker .react-datepicker__header {
    background-color: #F3F3F8;
  }
  /* .custom-datepicker .react-datepicker__header {
    background-color: #F3F3F8;
  } */

}
.msgr-name {
  min-height: 200px;
}
.msgr-name {
  align-items: center;
}
.type-msg textarea {
  max-width: 100%;
  min-width: 100%;
  border: none;
  background-color: transparent;
  min-height: 78px;
  max-height: 204px;
}
.type-msg textarea:focus{
  box-shadow: none;
}
.remders {
  background-color: #fff;
  padding: 20px;
}
.remders .card {
  margin-bottom: 20px;
  background-color: #F3F3F8;
  box-shadow: none;
}
.remders {
	height: 702px;
	overflow-y: scroll;
}
.dis-none{
  display: none;
}
.msg-box-cnt {
	height: 472px;
	padding: 12px;
	overflow-y: scroll;
}

.scroller-remove{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scroller-remove::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.chat-box {
  background-color:#f3f3f8;
}
.atched {
  background-color:#e8e8ec;
}
.participant-form .card-details input, .participant-form .card-details select, .premises-form input:not(input[type="radio"]), .premises-form .card-details select, .cnt-edt input:not(input[type="radio"]), .cnt-edt select {
	background-color: #f3f3f8;
	border-radius: 30px;
	height: 35px;
	padding: 4px 18px;
	width:280px;
	font-size: 14px;
}
.dub-input input, .dub-input select {
	width: 133px !important;
}
.css-13cymwt-control{
  background: transparent !important;
  border: none !important;
}
.css-t3ipsp-control:hover{
  border-color: transparent !important;
}
.editor-wrapper {
  min-height: 48vh; /* Initial height */
  max-height: 50vh; /* Maximum height */
  overflow-y: auto;
  /* border: 1px solid #dcdcdc; */
  padding: 10px;
  border-radius: 5px;
}

.editor-content {
  min-height: 120px;
}